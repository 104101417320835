<template>
    <div class="sale" style="height: 100%;">
        <Top name="我要出售" email></Top>
        <van-sticky :offset-top="40">
          <van-tabs v-model="active" >
            <van-tab title="出售角色" :disabled="isTrue" to="/sale"></van-tab>
            <van-tab v-if="isShow" :title="str" to="/sale/saleitems"></van-tab>
          </van-tabs>
        </van-sticky>
        <!-- <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="height: calc(100% - 84px);overflow-y: auto;"> -->
            <router-view v-if="isRouterAlive"/>
        <!-- </van-pull-refresh> -->
    </div>
</template>
<script>
import Top from '@/components/top'

export default {
    name: 'Sale',
    components: {
        Top
    },
    data() {
        return {
            isLoading:false,
            active: 0,
            isRouterAlive:true,
            isTrue:false,
            str:'出售物品',
            isShow:true
        }
    },
    created(){
        // if(localStorage.getItem('type')=='18'){
        if(sessionStorage.getItem('type')=='18'){
            // this.isTrue = true
            this.$router.push('/sale/saleitems')
            this.str = '仙玉寄售'
        }else if(sessionStorage.getItem('type')=='24'){
            this.isShow = false
        }else if(sessionStorage.getItem('type')=='0'){
          this.isTrue = true
          this.$router.push('/sale/saleitems')
          this.str = '元宝寄售'
        }
    },
    methods: {
        onRefresh(){
            // console.log(222)
            this.isRouterAlive = false
            this.$nextTick(function () {

                this.isRouterAlive = true
            })
            setTimeout(()=>{
                this.isLoading = false
            },500)

            // this.isLoading = false
        },
        //tab切换
        tabsClick(name) {
            //console.log(name);
        }
    },
    watch: {
        $route: {
            immediate: true, // 一旦监听到路由的变化立即执行
            handler(to, from) {
                //console.log(to.name);
                switch(to.name) {
                    case 'Salerole':
                        this.active = 0;
                        break;
                    case 'Saleitems':
                        this.active = 1;
                        break;
                }
            },
        },
    }
}
</script>
<style>
.sale .van-tabs__wrap .van-tabs__line{
    background-color: #FF4E00;
}
.sale .van-tabs__wrap .van-tab--active{
    font-weight: 700;
    color: #FF4E00;
}
</style>
